<template>
	<div class="modal-overlay" @mouseover.self="state = 'wait'" @mouseout.self="state = 'idle'" @mousedown.left.self="state = 'down'" @mouseup.left.self="state == 'down' && closeModal()">
		<div class="modal-wrapper">
			<!-- Modal content -->
			<div class="modal-content">
				<button type="button" class="modal-close" @click="closeModal">
					<slot name="close-icon">
						<span class="modal-close-icon"></span>
					</slot>
				</button>
				<slot />
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
const props = defineProps({ overflowKey: { type: String, default: 'modal' } })
const emit = defineEmits(['close']);

const state = ref('idle');

const closeModal = () => {
	emit('close');
};
const { remove } = useBodyOverflowDependence()

useEventListener('keyup', (e)=>{	
	if (e.code === 'Escape') emit('close')
})

onUnmounted(() => {
	remove(props.overflowKey)
});
</script>

<style lang="scss">
// Классы для модального окна
.modal-overlay {
	background-color: rgba(0, 0, 0, 0.5); // bg-black/50
	position: fixed;
	z-index: 70;
	inset: 0;
	overflow-y: auto;
}

.modal-wrapper {
	z-index: 50;
	pointer-events: none;
	display: flex;
	min-height: 100%;
	align-items: center;
	justify-content: center;
	padding: 30px 8px;

	@media (max-width: 1023px) {
		padding: 60px 8px;
	}
}

.modal-content {
	position: relative;
	width: 100%;
	pointer-events: auto;
	background-color: var(--white);
	border-radius: 1.25rem; // rounded-5 = 5 * 0.25rem
	padding: 1rem; // p-4 = 4 * 0.25rem
	max-width: 652px;

	@media (min-width: 1024px) {
		padding: 2.5rem; // lg:p-10 = 10 * 0.25rem
		box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.16); // lg:shadow-[0_6px_18px_0] shadow-black/16
	}
}

.modal-close {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0; // -top-2.5 = -2.5 * 0.25rem
	right: -16px; // lg:-right-2 = -2 * 0.25rem
	transform: translateX(100%);
	border-radius: 50%; // rounded-full
	width: 2.25rem; // size-9 = 9 * 0.25rem
	height: 2.25rem;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.6); // bg-white/60
	transition: background-color 0.2s;

	span {
		transition: transform .3s ease;
	}

	&:hover {
		span {
			transform: rotate(90deg);
		}
	}

	@media (min-width: 1024px) {
		width: 3rem; // lg:size-12 = 12 * 0.25rem
		height: 3rem;
	}

	@media (max-width: 1024px) {
		top: -12px;
		right: 50%;
		transform: translateY(-100%) translateX(50%)
	}
}

.modal-close-icon {
	background: url('~/assets/images/svg/x.svg') no-repeat center;
	background-size: 100% 100%;
	width: 22px;
	height: 22px;

	@media (max-width: 1024px) {
		width: 16px;
		height: 16px;
	}
}
</style>
